<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-22 14:07:38
 * @LastEditors  : 贾峰
 * @LastEditTime : 2020-01-17 14:47:34
 -->
<template>
  <div class="mainbody" v-if="showpage">
    <div class="diabg" v-if="showdialog"></div>
    <!-- <div class="selbg" v-if="isSelectorShow"></div>
    <div class="mergebtn" v-if="isSelectorShow">  
            <div class="mergebtnleft" @click="selectall">全选</div>
            <div class="mergebtnright" @click="onSelectorConfirm">{{'合并('+favorites.length+')'}}</div>
      </div>
    <div class="selectmodal" v-if="isSelectorShow">
      <div class="closesel"></div>
      <div class="checklist">
        <md-check-list
         v-model="favorites"
         icon="success"
         icon-inverse=""
         :options="famdata1"
      />
      </div>
    </div> -->
    <div class="dialog" v-if="showdialog">
        <div class="addfamins"><p>HI,家庭的主人</p><p>请编辑家庭的信息</p></div>
        <md-field>
        <md-input-item
          v-model="editfamilyname"
          title="家庭名称"
          placeholder="家庭名称"
          align="right"
          :solid="false"
        ></md-input-item>
        <md-field-item
         title="合并家庭"
         @click="showSelector"
         :content="selectorValue"
         arrow
        />
        </md-field>
        <div class="opbtn">
          <div class="optnleft" @click="delfam">删除家庭</div>
          <div class="optnright" :style="'background:'+getStorage('theme','')" @click="savefamilyname">保存</div>
        </div>
        <md-selector
          v-model="isSelectorShow"
          :data="famdata"
          max-height="400px"
          title="家庭列表"
          large-radius
          okText="确认"
          cancelText="取消"
          is-check
          @confirm="onSelectorConfirm"
        >
        <template slot-scope="{ option, index, selected }">
         <div class="md-selector-custom-title">{{ option.text }}</div>
        </template>
        <div class="searchbar" slot="header">
          <md-input-item
          v-model="searchfamname"
          placeholder="请输入家庭名称">
          <md-icon name="search" slot="left" @click.stop="searchwhich"></md-icon>
          <md-icon name="fail" slot="right" @click.stop="clearsearchinput"></md-icon>
        </md-input-item>
        </div>
        </md-selector>
        <!-- <input
          type="text"
          class="inputname"
          v-model="editfamilyname"
          placeholder="请输入家庭名称"
        /> -->
        <img
          src="@/assets/abd/image/closedialog.png"
          class="closedialog"
          @click="closedialog"
        />
    </div>
    <div v-if="showedit === 'main'">
      <div class="usertitle" :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).centerbg+')'">
        <div class="usertitlelef">
          <img src="@/assets/abd/image/SAAS-76.png" class="headpic" alt="" />
          <img src="@/assets/abd/image/editfam1.png" v-if="personkey === '0'" @click.stop="editfam" class="editpic" alt="" />
        </div>
        
        <div class="userins">
          <div class="userleft">
            <p class="name">{{ familyname }}
            <!-- <span class="iconfont iconbianji" v-if="personkey === '0'" @click.stop="editfam"></span>
            <span class="iconfont iconmerge" v-if="personkey === '0'" @click.stop="mergefam"></span>
            <span class="iconfont iconshanchu4" v-if="personkey === '0'" @click.stop="delfam"></span> -->
            </p>
            <p class="detail">
              <img
                src="@/assets/abd/image/c-85.png"
                alt=""
                style="width:10px;height:10px;margin-right:5px;margin-left:0"
              />TA的详细信息都在这里
            </p>
          </div>
          <div class="qrpic" @click.stop="showtwopic" v-if="personkey === '0'">
            <qrcode-vue
              renderAs="svg"
              :value="twopictop"
              level="H"
            ></qrcode-vue>
            <p>扫码加入家庭</p>
          </div>
        </div>
      </div>
      <div class="baseinfo">
        <p>基本信息</p>
      </div>
      <div class="cusdetail">
        <div class="details">
          <p class="detailtop">保单数</p>
          <p class="detailbottom" :style="'color:'+getStorage('theme','')">
            {{ familydata.countPolicy }}<span>份</span>
          </p>
        </div>
        <div class="details">
          <p class="detailtop">年缴保费</p>
          <p class="detailbottom" :style="'color:'+getStorage('theme','')">{{ familydata.bmount }}<span>元</span></p>
        </div>
        <div class="details">
          <p class="detailtop">投保人数</p>
          <p class="detailbottom" :style="'color:'+getStorage('theme','')">{{ familydata.countTBR }}<span>位</span></p>
        </div>
        <div class="details">
          <p class="detailtop">被保人数</p>
          <p class="detailbottom" :style="'color:'+getStorage('theme','')">{{ familydata.countBBR }}<span>位</span></p>
        </div>
      </div>
      <div class="bottompart">
        <div class="topfun">
          <div class="topfunleft" @click="addpolicypic">
            <img src="@/assets/abd/image/s-104.png" />
            <div class="upright">
              <img src="@/assets/abd/image/s-107.png" alt="" />
              <p class="ptitle">上传保单</p>
              <p class="pdetails">获得更全面的</p>
              <p class="pdetails">保障分析</p>
            </div>
          </div>
          <div class="topfunright" @click="viewpolicy">
            <img src="@/assets/abd/image/s-106.png" />
            <div class="upright">
              <p class="ptitle">解析追踪</p>
              <p class="pdetails">获得更全面的</p>
              <p class="pdetails">保障分析</p>
            </div>
          </div>
        </div>
        <div class="bottomfun">
          <div class="bottomfunleft" @click="viewfamilypolicy">
            <img src="@/assets/abd/image/policy-1.png" />
            <div class="upright">
              <p class="ptitle">分析报告</p>
              <p class="pdetails">获得更全面的</p>
              <p class="pdetails">保障分析</p>
            </div>
          </div>
          <div class="bottomfunright" @click="tofamilylist">
            <img src="@/assets/abd/image/s-105.png" />
            <div class="upright">
              <p class="ptitle">家庭成员</p>
              <p class="pdetails">获得更全面的</p>
              <p class="pdetails">保障分析</p>
            </div>
          </div>
        </div>
        <div class="nbs" @click="toNBSreport" v-if="personkey==='0'">
          <img src="@/assets/abd/image/nbs.jpg" />
        </div>
        <div class="footer">
          <img src="@/assets/abd/image/c-81.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 二维码展示-->
    <!-- <md-dialog :closable="true" v-model="twopicshow">
      <qrcode-vue
        :value="twopictop"
        level="H"
        class="viewtwopic"
        renderAs="svg"
      ></qrcode-vue>
    </md-dialog> -->
    <dig :show.sync="twopicshow" :istext="false" title="扫码加入家庭">
        <qrcode-vue
        :value="twopictop"
        level="H"
        class="viewtwopic"
        renderAs="svg"
      ></qrcode-vue>
    </dig>
    <div class="navsetting" :style="'background:'+getStorage('theme','')" v-if="personkey==='0'&& showback === true">
      <div class="backbtn" @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
      <div class="backtohome" @click="backtohomepage">
        <span class="iconfont iconhome"></span>
      </div>
    </div>
    <!-- <div class="backbtn" @click="backtopage" v-if="$route.query.familyname">back</div>
    <div class="backtohome" @click="backtohomepage" v-if="$route.query.familyname">home</div> -->
    <div class="md-example-child md-example-child-toast md-example-child-toast-7">
      <md-toast ref="toast">{{toasttext}}</md-toast>
    </div>
  </div>
</template>
<script>
import {
  getfamilydetails,
  addfamilymember,
  showfamilymember,
  judgeisCustomer,
  editFamily,
  delFamilyno,
  famliyList,
  mergeFamily,
  selectFamilyByname
} from "@/api/abd/family";
import { getSelectList } from "@/api/agent/agent";
import { Toast, Dialog,CheckList } from "mand-mobile";
import { getStorage } from "@/lib/util";
import config from "@/config";
import QrcodeVue from "qrcode.vue";
import wx from "weixin-js-sdk";
import { GetwxConfig } from "@/api/abt/customerOperation/common/index";
import dialog from "@/components/dialog";
import { wechatshare } from "@/lib/wechat_share";
const { redirect_uri } = config;
export default {
  components: {
    QrcodeVue,
    dig: dialog,
    [CheckList.name]: CheckList,
    [Toast.component.name]: Toast.component,
  },
  data() {
    return {
      /* 修改家庭 */
      toasttext:'',
      showback: true,
      showdialog: false,
      editfamilyname: "",
      searchfamname:'',
      submitnameloading: false,
      isSelectorShow: false,
      selectorValue:'',
      famdata: [],
      favorites:[],
      famdata1:[],
      /* 二维码 */
      twopicshow: false,
      twopictop: "",
      showpage: false,
      familyname: "",
      familydata: {
        bmount: 0,
        countBBR: 0,
        countPolicy: 0,
        countTBR: 0,
        familyno: ""
      },
      /* 弹出层 */
      submitstatus: false, // 保存按钮
      showedit: "main",
      insurants: "0",
      sex: "1",
      isDatePickerShow: false,
      minDate: new Date("2000/1/1"),
      cardtype: "",
      cardSelectorShow: false,
      cardselectdata: [],
      cardnum: "",
      birth: "",
      name: "",
      addform: {
        name: "",
        relation: "0",
        sex: "1",
        birthdate: "",
        cardtype: "",
        cardno: ""
      },
      membercount: 0,
      personkey: "",
      familyno: "",
      user: {},
      empno: "",
      empuserid: "",
      fromwhere: ""
    };
  },
  watch: {
    isSelectorShow(val){
      this.showback = !val
    }
  },
  created() {
    this.fromwhere = this.$route.query.fromwhere
      ? this.$route.query.fromwhere
      : "";
    wechatshare("", "", "", "", "", "", true);
    this.familyname = this.$route.query.familyname || "";
    this.user = getStorage("u_s", {});
    // this.twopictop = `${redirect_uri}/bdtg?familyno=${this.$route.query.familyno}&empno=${this.user.empno}`
    this.twopictop = `${redirect_uri}/recommendpage?comid=${
      this.user.comid
    }&familyno=${this.$route.query.familyno}&empno=${this.user.empno}`;
    // this.twopictop = `http://192.168.30.88:8080/recommendpage?comid=${this.user.comid}&familyno=${this.$route.query.familyno}&empno=${this.user.empno}`
    judgeisCustomer({ userid: this.user.userid, comid: this.user.comid }).then(
      res => {
        if (res.data.data.iscustomer) {
          // 客户或者业务员
          this.personkey = res.data.data.iscustomer;
          if (res.data.data.iscustomer === "1") {
            this.empno = res.data.data.empno;
            this.empuserid = res.data.data.empuserid;
          }
          if (res.data.data.familyname) {
            this.familyname = res.data.data.familyname;
          }
          res.data.data.familyno
            ? (this.familyno = res.data.data.familyno)
            : (this.familyno = "");
          if (this.$route.query.familyno || this.familyno !== "") {
            // 主页进来或者客户
            getfamilydetails({
              familyno: this.$route.query.familyno || this.familyno
            }).then(res => {
              this.familydata = res.data.data;
              this.showpage = true;
            });
          } else {
            // 业务员
            this.$router.push("/bdtg");
          }
        } else {
          // 什么也不是
          let newpath = "";
          if (window.__wxjs_is_wkwebview === true) {
            newpath =
              window.location.href.split("#")[0] || window.location.href;
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
              newpath = window.entryUrl;
            }
          } else {
            newpath = window.location.href;
          }
          // console.log(newpath)
          GetwxConfig({
            url: newpath
          }).then(res => {
            var wxconfig = res.data.data;
            wx.config({
              debug: false,
              appId: getStorage("a_d", ""),
              timestamp: wxconfig.timestamp,
              nonceStr: wxconfig.nonceStr,
              signature: wxconfig.signature,
              jsApiList: ["closeWindow"]
            });
            alert("请与身边的业务员联系");
            wx.ready(function() {
              wx.closeWindow();
            });
          });
        }
      }
    );
  },
  methods: {
    showtwopic() {
      this.twopicshow = true;
    },
    addpolicypic() {
      this.$router.push({
        path: "/addpolicy",
        query: {
          familyno: this.$route.query.familyno || this.familyno,
          personkey: this.personkey,
          empuserid: this.empuserid,
          fromwhere: this.$route.query.fromwhere || this.fromwhere
        }
      });
    },
    addfamember() {
      this.showedit = "dialog";
      if (this.cardselectdata.length === 0) {
        getSelectList({ list: ["BBCARD"] }).then(res => {
          res.data.data.forEach(item => {
            switch (item.label) {
              case "BBCARD":
                this.cardselectdata = item.value;
                this.cardselectdata.forEach(item => {
                  item.value = item.code;
                  item.text = item.name;
                });
                break;
              default:
                break;
            }
          });
        });
      }
    },
    /* 弹出层 */
    onDatePickerChange() {},
    onDatePickerConfirm(columnsValue) {
      this.addform.birthdate = this.$refs.datePicker2.getFormatDate(
        "yyyy-MM-dd"
      );
    },
    showcardSelector(selectag) {
      this.cardSelectorShow = true;
    },
    oncardtypeChoose({ value, text }) {
      this.cardtype = text;
      this.addform.cardtype = value;
      let cardnum = this.addform.cardno;
      if (cardnum.length === 10) {
        this.addform.birthdate =
          cardnum.slice(6, 10) +
          "-" +
          cardnum.slice(10, 12) +
          "-" +
          cardnum.slice(12, 14);
      }
    },
    inputcardnum() {
      let cardnum = this.addform.cardno;
      if (cardnum.length === 18) {
        if (this.cardtype === "身份证") {
          this.addform.birthdate =
            cardnum.slice(6, 10) +
            "-" +
            cardnum.slice(10, 12) +
            "-" +
            cardnum.slice(12, 14);
        }
      }
    },
    submit() {
      this.submitstatus = true;
      if (this.addform.name === "") {
        Toast.failed("姓名不能为空！");
        this.submitstatus = false;
      } else {
        let jsonarr = [];
        this.addform.empno =
          JSON.parse(sessionStorage.getItem("u_s")) === null
            ? JSON.parse(localStorage.getItem("u_s")).empno
            : JSON.parse(sessionStorage.getItem("u_s")).empno;
        this.addform.familyno = this.$route.query.familyno;
        jsonarr.push(this.addform);
        addfamilymember({ abtComEmpnomembers: JSON.stringify(jsonarr) })
          .then(res => {
            Toast.succeed("添加成功");
            this.addform = {
              name: "",
              relation: "0",
              sex: "1",
              birthdate: "",
              cardtype: "",
              cardno: ""
            };
            this.cardtype = "";
            this.showedit = "main";
            showfamilymember({ famliyno: this.$route.query.familyno }).then(
              res => {
                this.membercount = res.data.data.length;
              }
            );
          })
          .finally(() => {
            this.submitstatus = false;
          });
      }
    },
    inputname() {},
    viewpolicy() {
      this.$router.push({
        path: "/upload",
        query: {
          familyno: this.$route.query.familyno || this.familyno,
          empno: this.empno || "",
          personkey: this.personkey,
          empuserid: this.empuserid,
          familyname: this.$route.query.familyname || this.familyname,
          fromwhere: this.$route.query.fromwhere || this.fromwhere
        }
      });
    },
    viewfamilypolicy() {
      this.$router.push({
        path: "/report",
        query: {
          familyno: this.$route.query.familyno || this.familyno,
          empno: this.empno || "",
          personkey: this.personkey,
          empuserid: this.empuserid,
          familyname: this.$route.query.familyname || this.familyname,
          fromwhere: this.$route.query.fromwhere || this.fromwhere
        }
      });
    },
    tofamilylist() {
      this.$router.push({
        path: "/customer",
        query: {
          familyno: this.$route.query.familyno || this.familyno,
          personkey: this.personkey,
          empno: this.empno || "",
          empuserid: this.empuserid,
          familyname: this.$route.query.familyname || this.familyname,
          fromwhere: this.$route.query.fromwhere || this.fromwhere
        }
      });
    },
    backtopage() {
      this.$router.push({
        path: "/bdtg",
        query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true }
      });
    },
    backtohomepage() {
      this.$router.push({
        path: "/bdtg",
        query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true }
      });
    },
    editfam() {
      this.showdialog = true;
      // editFamily()
    },
    savefamilyname() {
      if (this.editfamilyname === "") {
        Toast.failed("请填写家庭名称");
      } else {
        this.submitnameloading = true;
        editFamily({
          familyno: this.$route.query.familyno,
          familyname: this.editfamilyname
        })
          .then(res => {
            this.showdialog = false;
            Toast.succeed("修改成功");
            this.familyname = this.editfamilyname
            this.editfamilyname = ''
            this.selectorValue = ''
          })
          .finally(() => {
            this.submitnameloading = false;
          });
      }
    },
    closedialog() {
      this.showdialog = false;
      this.editfamilyname = ''
    },
    mergefam() {},
    delfam() {
      Dialog.confirm({
        title: "确认删除",
        content: "请确认是否删除该家庭",
        confirmText: "确定",
        onConfirm: () => {
          delFamilyno({ familyno: this.$route.query.familyno }).then(res => {
            this.$router.push({
              path: "/bdtg",
              query: {
                fromwhere: this.fromwhere,
                bdtgflush: true
              }
            });
          });
        }
      });
    },
    showSelector() {
      this.isSelectorShow = true
      this.editfamilyname = ''
      famliyList({size:1000, page:1}).then(res=>{
      this.famdata = res.data.data.list
      this.famdata1 = res.data.data.list
      this.famdata.forEach(item=>{
        item.value = item.familyno
        item.text = item.familyname
      })
      this.famdata1.forEach(item=>{
        item.value = item.familyno
        item.label = item.familyname
      })
      this.famdata = this.famdata.filter(item => item.familyno!=this.$route.query.familyno)
    })
    },
    onSelectorConfirm({value,text}){
      this.selectorValue = text
      let arr = []
      arr.push(value)
      mergeFamily({
        oneFamilyno: this.$route.query.familyno,
        familynoList: arr
      }).then(res=>{
        this.$nextTick(()=>{
          this.$refs.toast.show()
          this.toasttext = `该家庭已和【${text}】合并`
          setTimeout(() => {
            this.$refs.toast.hide()
          }, 3000);
        })
        // Toast.succeed(`该家庭已和【${text}】合并`)
      })
    },
    searchwhich(){
      if(this.searchfamname === ''){
        Toast.failed(请填写家庭名称)
      }else{
        selectFamilyByname({empno: this.user.empno, familyname:this.searchfamname}).then(res=>{
        this.famdata = res.data.data
        this.famdata.forEach(item=>{
        item.value = item.familyno
        item.text = item.familyname
      })
      })
      }
    },
    clearsearchinput(){
      this.searchfamname = ''
      this.selectorValue = ''
      this.showSelector()
    },
    toNBSreport(){
      //nbsReport
      this.$router.push({
        path: '/nbsReport',
        query: { familyname: this.familyname, familyno: this.$route.query.familyno || this.familyno,fromwhere: this.$route.query.fromwhere || this.fromwhere, comid: this.user.comid, empno: this.user.empno || this.empno,custom:'1',personkey:this.personkey}
      })
    }
  }
};
</script>
<style lang="stylus" scoped>
/deep/ .md-icon.icon-font.md {
  font-size: 45px;
}

.qrpic {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 0.8px solid rgba(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 10px;

  /deep/ svg {
    border-radius: 14px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    width: 5vh !important;
    height: 5vh !important;
  }

  p {
    font-family: PingFang SC;
    color: rgba(255, 255, 255, 0.8);
    font-size: 28px;
  }
}

/* 弹框二维码 */
.viewtwopic {
  /deep/ svg {
    width: 50vw !important;
    height: 50vw !important;
    padding-bottom: 1vw;
  }
}

/deep/ .w-dialog {
  width: 66vw !important;
  height: 68vw !important;

  .body {
    display: flex;
    justify-content: center;
  }
}

/deep/.md-dialog-body {
  position: relative;
  display: flex;
  justify-content: center;

  .md-dialog-close {
    position: absolute;
    top: 14px;
    right: 20px;
  }
}

.mainbody {
  background-color: #F7F6FB;
}

.usertitle {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 12vh;
  /* background: url('~@/assets/abd/image/s-101.png') no-repeat; */
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .usertitlelef {
    position: relative;

    .headpic {
      width: 12vw;
      height: 12vw;
      border-radius: 50%;
      margin-left: 7vw;
    }

    .editpic {
      width: 4.5vw;
      height: 4.5vw;
      position: absolute;
      bottom: 8%;
      left: 80%;
    }
  }

  .userins {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70vw;
    height: 12vh;
    margin-left: 3vw;

    .name {
      font-size: 38px;
      color: white;
      margin-bottom: 15px;

      .iconbianji {
        font-size: 34px;
        margin-left: 14px;
      }

      .iconmerge {
        font-size: 34px;
        margin-left: 14px;
      }

      .iconshanchu4 {
        font-size: 34px;
        margin-left: 14px;
      }
    }

    .detail {
      display: flex;
      align-items: center;
      color: #94CEEC;
      font-size: 22px;
      letter-spacing: 4px;
    }
  }
}

.baseinfo {
  height: 8vh;
  display: flex;
  align-items: center;
  background-color: white;

  p {
    font-size: 37px;
    margin-left: 7vw;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    color: #4B4B4B;
  }
}

.cusdetail {
  height: 14vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .details {
    width: 22vw;
    background-color: white;
    height: 10vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 3vw;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.175);

    .detailtop {
      color: rgba(159, 159, 159, 1);
      font-size: 32px;
      padding-bottom: 20px;
    }

    .detailbottom {
      display: flex;
      align-items: center;
      color: color-primary;
      font-size: 38px;
      font-weight: bold;

      span {
        color: #BDBDBD;
        margin-left: 1vw;
        font-size: 25px;
      }
    }
  }

  .details:nth-child(1) {
    margin-left: 3.5vw;
  }

  .details:nth-child(4) {
    margin-right: 3.5vw;
  }
}

.uploadpolicy {
  background-color: white;

  .uploadtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6vw;
    padding-bottom: 0;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    font-size: 32px;
    color: #4B4B4B;

    img {
      width: 2vw;
      height: 3vw;
    }
  }

  .uploadbody {
    display: flex;
    height: 20vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
      color: #AFAFAF;
      letter-spacing: 5px;
      margin-bottom: 30px;
    }

    img {
      width: 17vw;
      height: 6.5vw;
    }
  }
}

.family {
  background-color: white;
  margin-top: 25px;

  .familytitle {
    display: flex;
    align-items: center;
    padding: 6vw;
    padding-bottom: 4vw;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    font-size: 32px;
    color: #4B4B4B;

    img {
      width: 45vw;
      margin-left: 4vw;
    }
  }

  .familybody {
    display: flex;
    padding-left: 6vw;
    height: 20vh;

    .familyleft {
      width: 22vw;
      height: 15vh;
      padding: 3vw;
      padding-bottom: 4vw;
      background-color: #E9F4F8;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        padding: 8px 0;
        color: #228BCE;
        font-size: 28px;
        letter-spacing: 2px;
      }

      p:nth-child(2) {
        font-size: 40px;
        font-weight: bold;
        margin-right: 15px;

        span {
          font-size: 26px;
          font-weight: normal;
        }
      }

      img {
        width: 16vw;
        height: 5vw;
      }
    }
  }
}

.report {
  background-color: white;
  margin-top: 25px;

  .reportchild {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6vw;
    height: 10vh;
    border-bottom: 1px solid #F2F2F2;
  }

  .reprotleft {
    display: flex;
    align-items: center;

    img {
      width: 3vw;
      height: 4vw;
    }

    p {
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      font-weight: bold;
      font-size: 32px;
      color: #4B4B4B;
      margin-left: 20px;
    }
  }

  .reportchild:nth-child(2) {
    .reprotleft {
      img {
        width: 3.5vw;
        height: 3vw;
      }
    }
  }

  .reportright {
    width: 2vw;
    height: 3vw;
  }
}

.footer {
  position: absolute;
  height: 8vh;
  display: flex;
  align-items: center;
  bottom: 0;

  img {
    height: 3vh;
  }
}

/* 弹出层 */
.addfamember {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #F7F6FB;

  .addfamily-title {
    height: 8vh;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0 4vw;
    justify-content: space-between;

    span {
      font-size: 36px;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      font-weight: bold;
      color: #4B4B4B;
    }
  }

  .addfamily-body {
  }

  /deep/ .md-button {
    background-color: #228BCF;
    height: 6vh;
    border-radius: 8px;
    width: 90vw;
    margin: 0 5vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4vh;
  }

  /deep/ .md-field-item-control {
    display: flex;
    justify-content: flex-end;
  }

  /deep/ .require {
    .md-field-item-title::after {
      content: '*';
      color: red;
    }
  }
}

/* 家庭列表 */
.familyright {
  width: 65vw;

  .familylist {
    width: 100%;
    height: 14vh;
    list-style: none;

    .listitem {
      height: 14vh;
      width: 33.3%;
      float: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 10vw;
        height: 10vw;
      }

      span {
        padding-top: 1vh;
        color: #A0A0A0;
      }
    }
  }
}

.topfun {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .topfunleft {
    background-color: white;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F5F4F7;
    margin-right: 10px;
    border-radius: 10px;

    .upright {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pdetails {
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(163, 163, 163, 1);
        font-size: 26px;
        letter-spacing: 3px;
      }

      img {
        width: 15vw;
        height: 6vw;
        position: absolute;
        bottom: 14vh;
        left: 7.5vw;
      }
    }

    img {
      width: 7vw;
      height: 6vw;
      margin: 0 30px;
    }

    .ptitle {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(56, 56, 56, 1);
    }
  }

  .topfunright {
    background-color: white;
    margin-left: 10px;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F5F4F7;
    border-radius: 10px;

    .upright {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pdetails {
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(163, 163, 163, 1);
        font-size: 26px;
        letter-spacing: 3px;
      }
    }

    img {
      width: 7vw;
      height: 7vw;
      margin: 0 30px;
    }

    .ptitle {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(56, 56, 56, 1);
    }
  }
}

.bottomfun {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .bottomfunleft {
    background-color: white;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F5F4F7;
    margin-right: 10px;
    border-radius: 10px;

    .upright {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pdetails {
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(163, 163, 163, 1);
        font-size: 26px;
        letter-spacing: 3px;
      }
    }

    img {
      width: 7vw;
      height: 7vw;
      margin: 0 30px;
    }

    .ptitle {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(56, 56, 56, 1);
    }
  }

  .bottomfunright {
    background-color: white;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #F5F4F7;
    margin-left: 10px;
    border-radius: 10px;

    .upright {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pdetails {
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(163, 163, 163, 1);
        font-size: 26px;
        letter-spacing: 3px;
      }
    }

    img {
      width: 7vw;
      height: 7vw;
      margin: 0 30px;
    }

    .ptitle {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(56, 56, 56, 1);
    }
  }
}
.nbs{
  height: 14vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding 0 5vw
}
.bottompart {
  background-color: white;
}

.navsetting {
  width: 10vw;
  height: 20vw;
  border-radius: 10px;
  background-color: color-primary;
  position: fixed;
  right: 10px;
  z-index: 900;
  bottom: 40%;
}

.backbtn {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  border-bottom: 0.8px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 50px;
  }
}

.backtohome {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 50px;
  }
}

.diabg {
  position: absolute;
  z-index: 111;
  background-color: black;
  opacity: 0.6;
  width: 100vw;
  height: 100vh;
}

.dialog {
  position: absolute;
  width: 80vw;
  height: 640px;
  z-index: 120;
  border-radius: 27px;
  background-color: white;
  top: 220px;
  left: 10vw;
  .addfamins {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 22px 6vw;
    border-radius: 10px 23px 0 0;
    /* background: url('~@/assets/abd/image/addfamname.png') no-repeat; */
    background-size: 102% 100%;

    p:nth-child(1) {
      font-family: PingFang SC;
      color: black;
      font-size: 56px;
      font-weight: bold;
    }

    p:nth-child(2) {
      font-family: PingFang SC;
      color: rgba(201, 201, 201, 1);
    }
  }

  /deep/ .md-button {
    background-color: color-primary;
    height: 7vh;
    border-radius: 8px;
    width: 90%;
    margin: 5vw auto;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inputname::-webkit-input-placeholder { /* WebKit browsers */
    color: #ced0d6;
    font-family: PingFang SC;
    color: rgba(157, 157, 157, 1);
    font-weight: 500;
    font-size: 35px;
  }

  .closedialog {
    position: absolute;
    z-index: 121;
    margin-top: 320px;
    width: 80px;
    height: 80px;
    left: 36vw;
  }

  /deep/ .md-input-item-input {
    font-size: 44px;
  }

  /deep/ .md-field-item-title {
    font-size: 44px;
  }

  /deep/ .md-field {
    padding: 0 6vw;
  }

  .opbtn {
    display: flex;
    height: 140px;
    width: 100%;
    position: absolute;
    top: 500px;
    z-index: 121;

    .optnleft {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      color white
      background: red;
      border-radius: 0 0 0 25px;
    }

    .optnright {
      width: 50%;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      background: color-primary;
      border-radius: 0 0 25px 0;
    }
  }
}
  .mergebtn{
    width 100vw
    height 8vh
    display: flex;
    position: fixed ;
    bottom 0
    z-index 889
    .mergebtnleft{
      width 50%
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
    }
    .mergebtnright{
      width 50%
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      background: color-primary;
    }
  }
  .selectmodal{
    height 70vh
    width 100vw
    padding 0 6vw
    bottom 8vh
    position absolute
    z-index 888
    border-radius 38px 38px 0 0
    background white
    .closesel{
      height 6vh
    }
    .checklist{
      height 64vh
      overflow-y auto
    }
  }
  .selbg{
    position: absolute;
    z-index: 887;
    background-color: black;
    opacity: 0.7;
    width: 100vw;
    height: 100vh;
  }
  /deep/ .md-field-item-control {
    display: flex;
    justify-content: flex-end;
    font-size: 44px;
  }
  .searchbar{
    border 1px solid rgb(239,239,239)
    border-radius 50px
    padding 0 20px 
    margin 20px 14px
  }
  .md-selector{
     /deep/ .md-popup-box {
     height: 100vh !important;
   }
  }

</style>
